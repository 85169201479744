import { Atom } from ":mods";

export function createResidencyTutorInputs() {
  const IsSaudi = Atom.Form.createInput("", {
    required: true,
  });
  const IsSaudiResident = Atom.Form.createInput("", {
    required: true,
  });
  const Country = Atom.Form.createSelect("", {
    required: !!IsSaudiResident.control.value ? true: false,
  });
  console.log("!!IsSaudiResident.control.value",!!IsSaudiResident.control.value)
  const Region = Atom.Form.createSelect("", {
    required: !!IsSaudiResident.control.value,
  });

  const Actions = Atom.Form.createFormActions({
    IsSaudi,
    IsSaudiResident,
    Country,
    Region,
  });
  return {
    Actions,
    IsSaudi,
    IsSaudiResident,
    Country,
    Region,
  };
}
